var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(this.$route.query.packageId !== 'null')?_c('div',[_c('b-breadcrumb',{staticClass:"mb-2"},[_c('b-breadcrumb-item',{attrs:{"to":"/orders/recurring"}},[_vm._v("Packages")]),_c('b-breadcrumb-item',[_c('router-link',{attrs:{"to":{ name: 'commande', params: { id: this.$route.query.packageId } }}},[_vm._v("Commandes")])],1),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("Nouvelle demande")])],1)],1):_c('div',[_c('b-breadcrumb',{staticClass:"mb-2"},[_c('b-breadcrumb-item',{attrs:{"to":"/orders/recurring"}},[_vm._v("Packages")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("Nouvelle demande")])],1)],1),_c('new-client',{attrs:{"is-add-new-user-sidebar-active":_vm.isAddNewUserSidebarActive},on:{"update:isAddNewUserSidebarActive":function($event){_vm.isAddNewUserSidebarActive=$event},"update:is-add-new-user-sidebar-active":function($event){_vm.isAddNewUserSidebarActive=$event}}}),_c('b-card',[_c('validation-observer',{ref:"addNewOrder",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('b-form',[_c('b-row',[(_vm.$route.query.client_hide == 'false')?_c('b-col',{attrs:{"cols":"12","md":"10","lg":"8","sm":"6"}},[_c('b-row',[_c('b-col',{staticClass:"pr-1",attrs:{"cols":"12"}},[(_vm.getCustomer !== null)?_c('b-form-group',{attrs:{"label":"Client","label-for":"Client"}},[_c('b-form-input',{attrs:{"id":"customer","type":"text","readonly":""},model:{value:(_vm.clientName),callback:function ($$v) {_vm.clientName=$$v},expression:"clientName"}})],1):_c('validation-provider',{attrs:{"name":"Client","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Client","label-for":"Client"}},[_c('v-select',{attrs:{"id":"customer","reduce":function (customer) { return customer.id; },"label":"name","options":_vm.customerOptions,"state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [(_vm.isCustomersWithoutPaginationLoading)?_c('b-spinner',{staticClass:"align-middle text-info",staticStyle:{"width":"2.5rem","height":"2.5rem"}}):_c('div',[_c('span',{staticClass:"mt-1 font-medium-1"},[_vm._v("Aucun client ne correspond à ces détails")]),_c('li',{staticClass:"text-success mt-1 mb-1 font-medium-1",staticStyle:{"text-align":"center","cursor":"pointer"},on:{"click":function($event){_vm.isAddNewUserSidebarActive = true}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"16"}}),_c('span',{staticClass:"text-center align-middle ml-25"},[_vm._v("Inscrire le client")])],1)])]},proxy:true}],null,true),model:{value:(_vm.forms.customer_id),callback:function ($$v) {_vm.$set(_vm.forms, "customer_id", $$v)},expression:"forms.customer_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1)],1):_vm._e(),(_vm.$route.query.client_hide == 'false')?_c('b-col',{staticClass:"d-flex align-items-center justify-content-end mb-2",attrs:{"cols":"12","md":"4","lg":"4","sm":"6"}},[_c('b-button',{staticClass:"mt-2 font-weight-normal font-medium-1",attrs:{"variant":"primary","block":""},on:{"click":function($event){_vm.isAddNewUserSidebarActive = true}}},[_vm._v(" Inscrire le client ")])],1):_vm._e()],1),_vm._l((_vm.forms),function(form,index){return _c('b-row',{key:index,attrs:{"id":"new-client-form"}},[(_vm.$route.query.search_employee === 0)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Prénom de l'employé","label-for":"firstname"}},[_c('validation-provider',{attrs:{"name":" prenom & nom ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstname","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Entrez le Prénom l'employé"},model:{value:(form.employee_first_name),callback:function ($$v) {_vm.$set(form, "employee_first_name", $$v)},expression:"form.employee_first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Service recherché","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Service recherché","label-for":"services"}},[_c('v-select',{attrs:{"id":"role","reduce":function (service) { return service.id; },"label":"name","options":_vm.recurringServicesOption,"state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [(_vm.isServicesRecurringWithoutPaginationLoading)?_c('b-spinner',{staticClass:"align-middle text-info",staticStyle:{"width":"2.5rem","height":"2.5rem"}}):_c('span',{staticClass:"mt-1 font-medium-1"},[_vm._v("Ce service n'existe pas")])]},proxy:true}],null,true),model:{value:(form.service_id),callback:function ($$v) {_vm.$set(form, "service_id", $$v)},expression:"form.service_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":form.budget_is_fixed === 1? 'Budget global':'Salaire net',"rules":"min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":form.budget_is_fixed === 1? 'Budget global':'Salaire net',"label-for":form.budget_is_fixed === 1?'employee_brut_salary': 'employee_salary'}},[_c('b-form-input',{attrs:{"id":_vm.inputId,"type":"number","state":errors.length > 0 ? false : null,"placeholder":form.budget_is_fixed === 1? 'Entrez le buget global': 'Entrez le salaire net de l\'employé'},model:{value:(_vm.InputVmodel),callback:function ($$v) {_vm.InputVmodel=$$v},expression:"InputVmodel"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Cnss","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Déclarer à la cnss","label-for":"cnss"}},[_c('b-form-select',{attrs:{"id":"cnss","options":_vm.cnssOptions,"state":errors.length > 0 ? false : null},model:{value:(form.cnss),callback:function ($$v) {_vm.$set(form, "cnss", $$v)},expression:"form.cnss"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Nombre d'employé","rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nombre d'employé","label-for":"number_of_employees"}},[_c('b-form-input',{attrs:{"id":"number_of_employees","type":"number","state":errors.length > 0 ? false : null,"placeholder":"Entrez le nombre d'employé"},model:{value:(form.number_of_employees),callback:function ($$v) {_vm.$set(form, "number_of_employees", $$v)},expression:"form.number_of_employees"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Fréquence d'intervation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Fréquence d'intervation","label-for":"intervention_frequency"}},[_c('b-form-select',{attrs:{"id":"intervention-frequency","options":_vm.interventionFrequencyOptions,"state":errors.length > 0 ? false : null},model:{value:(form.intervention_frequency),callback:function ($$v) {_vm.$set(form, "intervention_frequency", $$v)},expression:"form.intervention_frequency"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.frequency === 'others')?_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Frequence d'intervention","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"","label-for":"intervention-frequency"}},[_c('b-form-textarea',{attrs:{"id":"intervention","placeholder":"Plus d'infos la fréquence d'intervention","rows":"5","no-resize":"","state":errors.length > 0 ? false : null},model:{value:(_vm.other_intervention_frequency),callback:function ($$v) {_vm.other_intervention_frequency=$$v},expression:"other_intervention_frequency"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"quartier du client","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Quartier du client","label-for":"address"}},[_c('b-form-input',{attrs:{"id":"address","placeholder":"Quartier du client","state":errors.length > 0 ? false : null,"autocomplete":"off"},model:{value:(form.address),callback:function ($$v) {_vm.$set(form, "address", $$v)},expression:"form.address"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"exoneration"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Exonération des frais de placement","label-for":"placement_fees_is_exonerated"}},[_c('b-form-select',{attrs:{"id":"placement_fees_is_exonerated","options":_vm.exonerationOptions,"state":errors.length > 0 ? false : null},model:{value:(form.placement_fees_is_exonerated),callback:function ($$v) {_vm.$set(form, "placement_fees_is_exonerated", $$v)},expression:"form.placement_fees_is_exonerated"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(form.cnss === 0)?_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"budget_fixe"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Budget fixe","label-for":"budget_is_fixed"}},[_c('b-form-select',{attrs:{"id":"budget_is_fixed","options":_vm.fixedBudgetOptions,"state":errors.length > 0 ? false : null},model:{value:(form.budget_is_fixed),callback:function ($$v) {_vm.$set(form, "budget_is_fixed", $$v)},expression:"form.budget_is_fixed"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-col',{staticClass:"mb-2",attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Cahier des charges","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Cahier des charges","label-for":"needs"}},[_c('b-form-textarea',{attrs:{"id":"needs","placeholder":"Cahier des charges du client","state":errors.length > 0 ? false : null,"rows":"5","no-resize":""},model:{value:(form.other_wish),callback:function ($$v) {_vm.$set(form, "other_wish", $$v)},expression:"form.other_wish"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12","md":"4"}},[_c('b-button',{attrs:{"variant":"danger","disabled":_vm.forms.length == 1},on:{"click":function($event){return _vm.removeItem(index)}}},[_vm._v(" Supprimer ")])],1)],1)})],2),_c('b-row',[_c('b-col',{staticClass:"mb-4 d-flex align-items-center justify-content-start mb-2",attrs:{"cols":"12","md":"8"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.repeateAgain}},[_vm._v(" Ajouter un autre service ")])],1),_c('b-col',{staticClass:"d-flex align-items-center justify-content-end mb-2",attrs:{"md":"4"},on:{"click":function($event){return _vm.applyAddNewOrder()}}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary","disabled":_vm.isAddNewOrderLoading}},[_vm._v(" "+_vm._s(!_vm.isAddNewOrderLoading ? "Passer la demande" : "Chargement...")+" ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }