var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-user-sidebar","visible":_vm.isAddNewUserSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-close-on-backdrop":"","no-close-on-esc":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-add-new-user-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"\n          d-flex\n          justify-content-between\n          align-items-center\n          content-sidebar-header\n          px-2\n          py-1\n        "},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.accountActivationState ? 'Activer le compte du client' : 'Inscription client')+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"addNewCustomer",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.applyAddNewCustomerAction()},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"compteType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Type du compte","label-for":"compteType"}},[_c('b-form-select',{attrs:{"id":"compteType","options":_vm.compteOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.userData.is_company),callback:function ($$v) {_vm.$set(_vm.userData, "is_company", $$v)},expression:"userData.is_company"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.userData.is_company == 1)?_c('validation-provider',{attrs:{"name":"nomEntreprise","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nom de l'entreprise ","label-for":"nomEntreprise"}},[_c('b-form-input',{attrs:{"id":"nomEntreprise","autofocus":"","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.userData.company_name),callback:function ($$v) {_vm.$set(_vm.userData, "company_name", $$v)},expression:"userData.company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),_c('validation-provider',{attrs:{"name":"Nom et Prénom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.userData.is_company == 1 ?'Nom et Prénom du dirigeant' : 'Nom et Prénom',"label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","autofocus":"","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.fullName),callback:function ($$v) {_vm.fullName=$$v},expression:"fullName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Adresse mail","label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"ifu","rules":"length:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Numéro IFU","label-for":"ifu"}},[_c('b-form-input',{attrs:{"id":"ifu","type":"number","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.userData.company_ifu),callback:function ($$v) {_vm.$set(_vm.userData, "company_ifu", $$v)},expression:"userData.company_ifu"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),(_vm.userData.is_company == 1)?_c('validation-provider',{attrs:{"name":"siège de l'entreprise","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Siège de l'entreprise","label-for":"company_address"}},[_c('b-form-input',{attrs:{"id":"company_address","placeholder":"Siège de l'entreprise","state":errors.length > 0 ? false : null,"autocomplete":"off"},model:{value:(_vm.userData.company_address),callback:function ($$v) {_vm.$set(_vm.userData, "company_address", $$v)},expression:"userData.company_address"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),_c('validation-provider',{attrs:{"name":"Numéro de téléphone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Numéro de téléphone","label-for":"phone"}},[_c('b-input-group',[_c('b-input-group-prepend',{class:errors[0] ? 'border-danger' : '',attrs:{"is-text":""}},[_vm._v(" BJ (+229) ")]),_c('cleave',{staticClass:"form-control",class:errors[0] ? 'border-danger' : '',attrs:{"id":"phone","raw":false,"options":_vm.options.phone,"placeholder":"12 34 56 78"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","disabled":_vm.isAddNewCustomerLoading,"type":"submit"}},[_vm._v(" "+_vm._s(!_vm.isAddNewCustomerLoading ? "Soumettre" : "Chargement...")+" ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }